/* all */  
html {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #141d26;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.navbar {
  width: 100%;
  margin: 0 auto;
  padding-top: 12px;
  margin-bottom: 30px;
  font-weight: 600;
  background-color: #141d26;
}

.navbar-nav {
  display: flex;
  justify-content: right; /* Centers the nav links within the Nav component */
  width: 100%;
}

.navbar-brand {
  font-size: 20px;
  font-weight: 500;
  padding-top: .14rem;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(6, 135, 245, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.App {
  text-align: center;
}

.reflect {
  display: block;
  transform-origin: right;
  transform: rotateX(180deg);
  background-image: linear-gradient(0deg, #202E3C 0, transparent 95%);
  -webkit-background-clip: text;
  color: transparent;
}

.blend {
  color: #141d26;
}

.longForm {
  width: 100%;
  font-size: 14px;
  text-align: left;
  margin-bottom: 115px;
}

.release-note {
  margin-bottom: 40px;
  margin-top: 40px;
  color: lightskyblue;
}

.question {
  margin-top: 40px;
  color: lightskyblue;
}

.content-entry {
  margin-bottom: 40px;
}

.intro {
  color: lightskyblue;
  margin-bottom: 40px;
}

hr {
  border-color: lightgray;
  margin-top: 40px;
  margin-bottom: 40px;
}

.closing {
  margin-top: 40px;
  margin-bottom: 40px;
}

body {
  background-color: #141d26;
  color: lightgray;
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
}

.collapse-menu {
  text-align: left;
}

.embed-container {
  margin-bottom: 145px;
}

.artist-name {
  margin-left: 30px;
}

.about-container {
  float: none;
  margin: 0 auto;
  margin-bottom: 150px;
  width: 100%;
  text-align: left;
}

.zerofavcontainer {
  min-height: 500px;
}

.container {
  position: relative;
  float: none;
  padding-bottom: 10px;
}

.random-container {
  padding: 0px 15px 10px;
}

.footer {
  margin-bottom: 40px;
  background-color: #141d26;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 12px;
  color: lightgray;
}

.footer-small {
  font-size: 10px;
  color: gray;
}

.hvr-grow {
  display: inline-block;
  vertical-align: right;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.iframe-embed {
  width: 100%;
  height: 460px;
  border: 0px;
}

.placeholder {
  width: 100%;
  height: 350px;
  border: 0.5px solid lightgray;
  margin-bottom: 15px;
  border-color: #0687f5;
}

.page-title {
  margin-bottom: 30px;
  font-size: 16px;
}

.mobile-title {
  font-weight: 600;
}

.box {
  display: inline-block;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.black:hover {
  background-color: #5e5d5d;
}

.black {
  background-color: #363636;
}

.gray:hover {
  background-color: #E0E0E0;
}

.gray {
  background-color: #9E9E9E;
}

.white:hover {
  background-color: white;
}

.white {
  background-color: #ebedf0;
}

.yellow:hover {
  background-color: #f6e2a1;
}

.yellow {
  background-color: #e3ca77;
}

.green:hover {
  background-color: #558B2F;
}

.green {
  background-color: #33691E;
}

.blue:hover {
  background-color: #1565C0;
}

.blue {
  background-color: #01579B;
}

.purple:hover {
  background-color: #8E24AA;
}

.purple {
  background-color: #6A1B9A;
}

.red:hover {
  background-color: #C62828;
}

.red {
  background-color: #B71C1C;
}

.pink:hover {
  background-color: #F06292;
}

.pink {
  background-color: #EC407A;
}

.input-short {
  background-color: lightgray;
  margin: auto;
  width: 65%;
}

.input-long {
  background-color: lightgray;
  margin: auto;
  width: 90%;
}

.sticky-nav {
  position: sticky;
  top: 0;
  background-color: #141d26;
}

.color-picker {
  margin-bottom: 40px;
}

/* iphone xr */
@media only screen and (min-width: 375px) {
  .box {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
}

/* laptop */  
@media only screen and (min-width: 481px) {
  .box {
    display: inline-block;
    width: 55px;
    height: 55px;
    cursor: pointer;
  }

  .color-picker {
    margin-bottom: 50px;
  }

  .sticky-nav {
    position: sticky;
    top: 0;
    background-color: #141d26;
  }

  .iframe-embed {
    width: 500px;
    height: 625px;
  }

  .about-container {
    width: 500px;
  }

  .longForm {
    font-size: 14px;
    text-align: left;
    width: 500px;
    margin-bottom: 115px;
  }

  .placeholder {
    width: 500px;
    height: 500px;
    border: 0.5px solid lightgray;
    margin-bottom: 24px;
    border-color: #0687f5;
  }

  .navbar {
    width: 500px;
    margin: 0 auto;
    padding-top: 12px;
    margin-bottom: 32px;
    font-size: 16px;
    border-bottom: 1px solid #0687f5;
    background-color: #141d26;
  }

  .embed-container {
    margin-bottom: 175px;
  }

  .random-container {
    padding: 0px 0px 0px;
  }

  .input-short {
    background-color: lightgray;
    margin: auto;
    width: 55%;
  }

  .form-group {
    margin: auto;
    margin-bottom: 15px;
    width: 500px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.8em;
    padding-left: 0.8em;
  }

  .content-entry {
    margin-bottom: 60px;
  }

  .page-title {
    margin-bottom: 32px;
  }

  .mobile-title {
    display: none;
  }
}

/* desktop */  
@media only screen and (min-width: 1601px) {
  .page-title {
    font-size: 18px;
    padding-bottom: 20px;
    margin-top: 50px;
    margin-bottom: 32px;
  }

  .color-picker {
    margin-bottom: 70px;
    margin-top: 40px;
  }

  .box {
    display: inline-block;
    width: 66.6px;
    height: 66.6px;
    cursor: pointer;
  }

  .hvr-grow {
    margin-top: 12px;
  }

  .sticky-nav {
    position: sticky;
    top: 0;
    background-color: #141d26;
  }

  .iframe-embed {
    width: 600px;
    height: 720px;
  }

  .longForm {
    text-align: left;
    width: 500px;
    margin-bottom: 115px;
  }

  .placeholder {
    width: 600px;
    height: 600px;
    border: 0.5px solid lightgray;
    margin-bottom: 24px;
    border-color: #0687f5;
  }

  .navbar {
    width: 600px; /* Matches the embed container width */
    margin: 0 auto; /* Center the navbar */
    padding-top: 12px;
    margin-bottom: 32px;
    font-size: 18px;
    border-bottom: 1px solid #0687f5;
    background-color: #141d26;
  }

  .embed-container {
    margin-bottom: 175px;
  }

  .random-container {
    padding: 0px 0px 0px;
  }

  .about-container {
    font-size: 18px;
    width: 500px;
  }

  .input-short {
    background-color: lightgray;
    margin: auto;
    width: 55%;
  }

  .form-group {
    margin: auto;
    margin-bottom: 15px;
    width: 500px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.8em;
    padding-left: 0.8em;
  }

  .content-entry {
    margin-bottom: 60px;
    font-size: 16px;
  }

  .mobile-title {
    display: none;
  }

  .footer {
    font-size: 14px;
  }

  .footer-small {
    font-size: 12px;
  }
}